import React, {Component} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./login";
import { connect } from "react-redux";
import ProtectedRoute from './ProtectedRoute'
import  MonumentForm  from './monumentForm';
import  FinanceForm  from './financeForm';
import  ActionClientForm  from './actionClientForm';
import GRCEvent from './grcEvent';
import EventsGRC from './eventsGRC';
import EventEdit from "./eventEdit";
import {Alert} from "./alert";


class Routes extends Component {
  componentDidMount() {
    
  }

  render() {  
    let ml=""
    if (this.props.isLoggedIn)
      ml="ml-264"
    return (
      <div className={"app-main "+ml}>
        <Switch>
      
        <Route exact path="/login" render={(routerProps) =>  this.props.isLoggedIn? (
            <Redirect to="/evenements" />
          ) : (
            <Login history={routerProps.history} store={this.props}/>
          )
          }></Route>
          <ProtectedRoute exact path="/" component = {EventsGRC} store={this.props}/>
          <ProtectedRoute exact path="/evenementGRC" component = {GRCEvent} store={this.props}/>
          <ProtectedRoute exact path="/evenementshow" component = {EventEdit} store={this.props}/>
          <ProtectedRoute exact path="/evenements" component = {EventsGRC} store={this.props}/>
          <ProtectedRoute exact path="/demandeMonument" component = {MonumentForm} store={this.props}/>
          <ProtectedRoute exact path="/appuiFinance" component = {FinanceForm} store={this.props}/>
          <ProtectedRoute exact path="/actionClient" component = {ActionClientForm} store={this.props}/>
          <ProtectedRoute exact path="/alertsuccess" component = {Alert}/>
    
    
        </Switch>
      </div>
    );
  }
};

const mapStateToProps = ({ usersReducer, eventGRC }) => {
  const { isLoggedIn, user, params, contacts, token } = usersReducer;
  return {
    isLoggedIn: isLoggedIn,
    user: user,
    params: params,
    contacts: contacts,
    eventGRC: eventGRC
    
  };
};

export default connect(mapStateToProps)(Routes);
