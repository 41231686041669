import "./loader.css";

export default function Loader(props) {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        height:"50vh",
        alignItems:"center"
      }}
    >
      <div style={{ position: "absolute", zIndex: "10",borderRadius:"5px",padding: "15px",display:"flex",alignItems:"center",color: "#fff",background: "rgb(38 38 38 / 80%)"}}>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <div style={{paddingRight: "8px"}}>Chargement</div>
      </div>
    </div>
  );
}
