import { ADD_MONUMENT, SHOW_MESSAGE, LOGOUT} from "./types";
import http from '../lib/axios'
import httpForm from "../lib/axios/httpform";

export function monumentRequest(monument, ownProps) {
    return (dispatch) => {
        // const monument_form= `nb_request= ${monument.nb_request}&request_type= ${monument.request_type}
        //                 &family_reference= ${monument.family_reference}&description=${monument.description}
        //                 &request_attachments=${monument.request_attachment}`
      return httpForm.post(`/monument_request`, monument)
      .then((response) => {
        response.status !== 500 ?
      
          dispatch({
                type: ADD_MONUMENT,
                payload: {
                   show_message: true,
                },
                
              },ownProps.history.push({
                pathname:"/alertsuccess",
                state: { message: 'Votre demande a bien été envoyée' }
              })):
              dispatch({
                  type: SHOW_MESSAGE,
                  payload: {
                     show_message: false,
                  },
                })
           
        }).catch((e)=>{
          dispatch({
            type: LOGOUT
          },ownProps.history.push("/login"))
        });
    };
  }